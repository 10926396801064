import React, { useState } from "react";

import data from "../data/scuole-superiori";
function Test() {
  let ArrayComuni = [];

  function jsonfacose() {
    data.map((item) => {
      ArrayComuni.push(
        item["COMUNE"] + " | " + item["Denominazione dell'istituto"]
      );
    });

    console.log(ArrayComuni);
  }
  return <button onClick={() => jsonfacose()}>clik me</button>;
}

export default Test;
